import React, { Dispatch, SetStateAction } from 'react';

import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import Button from 'src/components/button/button';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import FormErrorMessage from 'src/components/form-error-message/form-error-message';
import { TextAreaField } from 'src/components/formik-fields/formik-fields';
import TextInput from 'src/components/input/input';
import { EDIT_RANKING_POINTS } from 'src/components/player-points-player/player-points-player.queries';
import { Body, H4 } from 'src/components/typography/typography';
import { GetPlayersPlayHistory_getPlayersPlayHistory_items as PlayersPlayHistoryItems } from 'src/graphql-types/GetPlayersPlayHistory';
import { PointsTypeEnum } from 'src/graphql-types/globalUstaTypes';
import * as Yup from 'yup';

import * as styles from './edit-event-points-dialog.module.less';

interface InitialValues {
  points: string;
  comment: string;
}

const initialValues: InitialValues = {
  points: '',
  comment: '',
};

interface EditEventPointsDialog {
  event: PlayersPlayHistoryItems | null;
  person: any;
  refetchEvents: any;
  setDialog: Dispatch<SetStateAction<boolean>>;
}

export const EditEventPointsDialog: React.FC<EditEventPointsDialog> = ({ event, person, refetchEvents, setDialog }) => {
  const { t } = useTranslation();

  const [editRankingPoints] = useMutation(EDIT_RANKING_POINTS, {
    client: meshGatewayClient,
  });

  const handleSubmit = async (values: InitialValues) => {
    await editRankingPoints({
      variables: {
        input: {
          eventId: event?.eventId,
          tournamentId: event?.tournamentId,
          playerId: person?.id,
          pointsAdjustment: Number(values.points),
          pointsType: event?.matchFormat,
          adjustmentDescription: values.comment,
          ...(event?.collectionId ? { collectionId: event.collectionId } : {}),
        },
      },
    });
    await refetchEvents();
    setDialog(false);
  };

  const formatValue = event?.matchFormat === PointsTypeEnum.SINGLES ? 'singles points' : 'doubles points';
  const formatLabel = t(formatValue);

  const validationSchema = Yup.object().shape({
    points: Yup.string()
      .matches(/^[0-9]+$/, `${formatLabel}' field is number entry only`)
      .required(t('are required', { type: formatValue })),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, setFieldValue, isSubmitting, errors, touched, setFieldTouched }) => (
        <Form>
          <CustomGrid container justifyContent="flex-end" spacing={{ margins: { lg: 'top' } }}>
            <Annotation event={event} />
            <Grid container direction="column">
              <Body size="lg" bold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                {event?.matchFormat === PointsTypeEnum.SINGLES ? t('singles points') : t('doubles points')}
              </Body>
              <TextInput
                value={values.points}
                onChange={(e) => {
                  setFieldValue('points', e.target.value);
                  setFieldTouched('points', true);
                }}
                disableUnderline
                outlined
                fullWidth
                style={{ width: '50%' }}
              />
              {errors.points && touched.points && (
                <FormErrorMessage spacing={{ margins: { md: 'top' } }} message={errors.points} />
              )}
              <Body size="lg" bold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                {t('reason for points update')}
              </Body>
              <TextAreaField name="comment" height="xxs" maxLength={100} />
              <Grid container alignItems="center" justifyContent="space-between">
                <span className={styles.charRemaining}>
                  {t('characters remaining', {
                    chars: 100 - (values.comment?.length || 0),
                  })}
                </span>
                <Button type="button" level="link" onClick={() => setFieldValue('comment', '')} noWidth>
                  <span className={styles.buttonText}>{t('clear')}</span>
                </Button>
              </Grid>
            </Grid>
            <CustomGrid container justifyContent="flex-end" spacing={{ margins: { lg: 'top' } }}>
              <Button onClick={() => setDialog(false)} type="button" level="tertiary" disabled={isSubmitting}>
                {t('cancel')}
              </Button>
              <Button type="submit" spacing={{ margins: { sm: 'left' } }} loading={isSubmitting}>
                {t('update points')}
              </Button>
            </CustomGrid>
          </CustomGrid>
        </Form>
      )}
    </Formik>
  );
};

const getTournamentName = ({ tournamentName, level, t }) => {
  const levelLabel = level ? `${t('level')} ${level}` : '';
  return levelLabel ? `${levelLabel} - ${tournamentName}` : tournamentName;
};

const Annotation = ({ event }: { event: PlayersPlayHistoryItems | null }) => {
  const { t } = useTranslation();

  return (
    <CustomGrid container className={styles.annotationContainer}>
      <H4 spacing={{ margins: { xxs: 'bottom', auto: 'top' } }}>
        {getTournamentName({
          tournamentName: event?.tournamentName,
          level: event?.level,
          t,
        })}
      </H4>
      <Grid container alignItems="center">
        <Body size="md">
          {t('member date', {
            date: dayjs(event?.tournamentStart).local(),
          })}
        </Body>
        {/* <div className={styles.ballSeparator} /> */}
        {/* <Body size="md">Code: {event?.defaultCodeClassId || t('n/a')}</Body> */}
      </Grid>

      <div className={styles.divider} />
      <Grid container>
        <Grid container item xs direction="column">
          <Body size="md" bold color={Body.color.SEMI_DARK_GREY}>
            {t('event')}
          </Body>
          <Body size="md">{event?.tournamentName || t('n/a')}</Body>
        </Grid>
        <Grid container item xs direction="column">
          <Body size="md" bold color={Body.color.SEMI_DARK_GREY}>
            {t('draw type')}
          </Body>
          <Body size="md">{event?.drawType || t('n/a')}</Body>
        </Grid>
      </Grid>
    </CustomGrid>
  );
};
